import './App.css';

const App = () => {
  const link = "https://redirecting8.eu/p/eVlP/8Kmc/d3L8"

  return (
    <>
      <div className='container'>
        <div className='title'><img src="/img/logo.png"/>Dwie pary GRATIS!</div>
        <div className='subtitle'>Najlepsze bokserki co miesiąc w Twojej skrzynce pocztowej , aby to udowodnić, pierwsze bokserki dostajesz od nas za darmo. Potem tylko 49 zł miesięcznie, dostawa zawsze gratis.</div>
      </div>
      <div className='c-btn'>
        <a href={link}>
          <button className='btn'>Przejdź do strony</button>
        </a>
      </div>
    </>
  );
}
export default App;
